<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to='/'>首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        菜单管理
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <!-- 工具栏 -->
      <el-form
        inline
        :model="query"
        label-position="right"
        label-width="60px"
        size='small'
        class="query-form"
      >
        <el-form-item
          label="搜索"
          prop="name"
        >
          <el-input
            v-model="query.name"
            placeholder="请输入关键词"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary">查询</el-button>
          <el-button type="success">新增</el-button>
          <el-button type="warning">删除</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div
          slot="header"
          class="clearfix"
        >
          <span>菜单数据列表</span>
          <el-button
            style="float: right; padding: 3px 0"
            type="text"
          >
            <i class="el-icon-document"></i>
            导出数据
          </el-button>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          border
          stripe
          style="width:100%;"
        >
          <el-table-column
            prop="Title"
            label="标题"
          > </el-table-column>
          <el-table-column label="是否删除">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.IsDelete"
                type="success"
              >是</el-tag>
              <el-tag
                v-else
                type="warn"
              >否</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="CreateTime"
            label="日期"
          > </el-table-column>
        </el-table>
        <!-- 分页条 -->
        <div
          class="block"
          style="margin-top:20px;"
        >
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[5, 20, 30, 50,100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <!-- 操作对话框 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: {},
      loading: false,
      currentPage4: 1,
      pageSize: 5,
      total: 0,
      tableData: []
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //获取刷新列表数据
    getList() {
      var _this = this;
      _this.loading = true;
      $.get(
        "http://localhost:55000/api/article/getlist",
        {
          pageIndex: _this.currentPage4,
          pageSize: _this.pageSize
        },
        data => {
          _this.loading = false;
          _this.tableData = data.list;
          _this.total = data.total;
        }
      );
    }
  },
  created() {
    this.getList();
  }
};
</script>